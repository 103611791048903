import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import {Table, Container, Button, Row, Col} from 'react-bootstrap'
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import Loader from '../../components/loading';
import StandardModal from '../../components/modal';

import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';

const PendingRefunds = () => {
  const [showLoader, setShowLoader] = useState(false)
  const [refundItems, setRefundItems] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');
  const [itemId, setItemId] = useState('')
  const [orderItem, setOrderItem] = useState({})

  const getRefundItems = () => {
    const url=`${constants.BACKEND_URL}/order-items/refunds`
    Fetch.get(url).then(data => {
     setRefundItems(data.data)
    })
  }

  const remarksField = (item) => {
    return (
      <>
        {
          item.refund_remarks ? (
            <div className="pointer text-wrap" style={{"whiteSpace": "pre-line"}}>
              {item.refund_remarks.split ('\n').map (
                (line, i) => {
                  return <div data-id={item.id} key={i}>{line}</div> 
                }
              )}
            </div>
          ) : (
            <></>
          )
        }
      </>
    )
  }

  const getOrderItemFromProdArray = (orderItemId) => {
    if (refundItems.length > 0) {
      const orderItem = refundItems.filter(item => {
        return item.id == Number(orderItemId)
      })
      return orderItem[0];
    }
  }
  

  useEffect(() => {
    getRefundItems()
    return () => {
      
    }
  }, [])

  const handleOnClick = (e) => {
    setItemId(e.target.dataset.id);
    setOrderItem(getOrderItemFromProdArray(e.target.dataset.id))
    setAction(e.target.dataset.action);
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  const handleModalConfirm = () => {
    setShowModal(false);
    let refundStatus = '';
    switch (action) {
      case 'approve':
        refundStatus = constants.REFUND_STATUS.APPROVED;
        break;
      case 'reject':
        refundStatus = constants.REFUND_STATUS.REJECTED;
        break;
      default:
        break;
    }
    setShowLoader(true)
    const url=`${constants.BACKEND_URL}/order-items/item/${itemId}/refund`
    const data={
      refundStatus,
      refundRemarks: orderItem.refund_remarks,
    }
    Fetch.patch(url, JSON.stringify(data)).then(data => {
      if (data && data.statusCode === 400) {
        setShowLoader(false);
        return;
      }
      setShowLoader(false);
      getRefundItems()
      patchStatusChangeLog(refundStatus)
    })
  }

  const patchStatusChangeLog = (refundStatus) => {
    const url=`${constants.BACKEND_URL}/order-items/item/${itemId}/log`
    const refundData={
      status: refundStatus === constants.REFUND_STATUS.APPROVED ? 'Refund Approved' : 'Refund Rejected'
    }
    Fetch.patch(url, JSON.stringify(refundData)).then(data => {
      if (data && data.statusCode === 400) {
        return;
      }
    })
  }

  return (
    <Layout pageInfo={{ pageName: "pendingRefunds" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Pending Refunds page" />
      <Row>
        <Col className="text-center mb-2">
          <h5>Pending Refunds</h5>
          <h6><small>- Refund Request Pending Approval -</small></h6>
        </Col>
      </Row>
      <Container>
        <Table>
          <thead>
            <tr>
              <th>Cust ID</th>
              <th>Order ID</th>
              <th>Item ID</th>
              <th>Fabric</th>
              <th>Item Type</th>
              <th className="text-right">Price</th>
              <th>Status</th>
              <th>Refund Reason</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {refundItems && Array.isArray(refundItems) && refundItems.length > 0 ?
              refundItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.custid}</td>
                    <td><Link to={`/orders/salesOrder?custId=${item.custid}&orderId=${item.order_id}`}>{item.order_id}</Link></td>
                    <td>{item.id}</td>
                    <td>{item.item_desc}</td>
                    <td>{item.item_type}</td>
                    <td className="text-right">${Number(item.priceAfterDiscount).toFixed(2)}</td>
                    <td>{item.refund_status.toUpperCase()}</td>
                    <td>{remarksField(item)}</td>
                    <td>
                      <Button className="mr-2" data-id={item.id} data-action="approve" onClick={handleOnClick}>Approve</Button>
                      <Button className="mr-2" data-id={item.id} data-action="reject" onClick={handleOnClick} variant="danger">Reject</Button>
                    </td>
                  </tr>
                )
              }) : <><tr><td>No Refund Items</td></tr></>
            }
          </tbody>
        </Table>
        <StandardModal 
          onConfirm={handleModalConfirm}
          onClose={handleModalClose} 
          show={showModal}
          title="Send Receipt"
          body={``}
        >
          
          Are you sure you want {action === 'approve' ? 'approve' : 'reject'} this refund?
        </StandardModal>

      </Container>
    </Layout>
  )
}

export default PendingRefunds