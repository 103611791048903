import React from 'react';
import { Spinner } from 'react-bootstrap'
import styles from './index.module.css';

const Loader = ({show, message=''}) => {
  return (
    <>
    {
      show && 
        <div className={styles.loaderBackground}>
          <Spinner animation="border" role="status" variant="light" className={styles.loaderImage}>
          </Spinner>
          <div className={styles.loaderText}>{message}</div>
        </div>
    }
    </>
    
  )
}

export default Loader